
export const loadGTM = (id: string) => {
    if (!id) return;
  
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(script);

    const configScript = document.createElement('script');
    configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${id}');
    `;
    document.head.appendChild(configScript);
  };

export const getGTMId = (): string => {
    const domain = window.location.hostname;
  
    if (domain.endsWith('.in')) {
      return 'G-5GCJL8W2YK'; 
    } else if (domain.endsWith('.com')) {
      return 'G-D903CELFLJ';
    }
  
    return ''; 
  };
  
  