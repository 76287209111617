import React, { useState } from "react";
import Delight from "../../../src/assets/images/delight.png";
import Delight1 from "../../../src/assets/images/delight1.png";
import Delight2 from "../../../src/assets/images/delight2.png";
import Delight3 from "../../../src/assets/images/delight3.png";
import Delight4 from "../../../src/assets/images/delight4.png";
import Delight5 from "../../../src/assets/images/delight5.png";
import Delight6 from "../../../src/assets/images/delight6.png";

import Delight7 from "../../../src/assets/images/Rectangle 1694 (9).png";
import Right from "../../../src/assets/images/right.png";
import ReceipeKit from "./ReceipeKit";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const categories = [
  { name: "Breakfast", img: Delight },
  { name: "Lunch", img: Delight1 },
  { name: "Dinner", img: Delight2 },
  { name: "Snacks", img: Delight3 },
  { name: "Dessert", img: Delight4 },
  { name: "Salad", img: Delight5 },
  { name: "Soups", img: Delight6 },
  { name: "Drinks", img: Delight7 },
  { name: "Shake", img: Delight7 },
];

const DelightReceipe: React.FC = () => {
  const [selectedRecipe, setSelectedRecipe] = useState("Lunch");
  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 8;

  const handleLeftClick = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleRightClick = () => {
    setStartIndex((prev) =>
      Math.min(prev + 1, categories.length - itemsToShow)
    );
  };

  const handleCategoryClick = (categoryName: string) => {
    setSelectedRecipe(categoryName);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div>
      <div className="p-6  mt-1 md:mt-2 lg:mt-10">
        <h2 className="text-xl md:text-2xl text-black font-bold mb-2 font-sans">
          Daily Delight Recipe Kit
        </h2>
        <div className="flex justify-between items-center mb-1 sm:pb-1">
          {selectedRecipe && (
            <div className="mt-4">
              <h3 className="text-l md:text-xl font-normal text-red font-sans">
                {selectedRecipe}
              </h3>
            </div>
          )}
 {/* <button className="text-sm md:text-l text-red border-b-2 border-red font-sans flex items-center flex-row pb-1 md:pb-2">     
         View All        
         <img src={Right} className="h-6 w-6 ml-2" />      
            </button>*/}
        </div>
        <Slider {...settings}>
          {categories
            .slice(startIndex, startIndex + itemsToShow)
            .map((category) => (
              <div key={category.name} className="font-sans text-center flex flex-col items-center p-3">
                <div 
                  className="cursor-pointer transform transition-transform duration-300 hover:scale-110 hover:translate-y-[-5px]"
                  // className={`cursor-pointer ${
                  //   selectedRecipe === category.name
                  //     ? "border-2 border-red md:rounded-[14px] sm:rounded-[6px]"
                  //     : "border-transparent"
                  // }`}
                  onClick={() => handleCategoryClick(category.name)}
                  
                >
                  <img
                    src={category.img}
                    alt={category.name}
                    //className="object-cover mt-2 p-3 "
                  />
                </div>
                <button
                  className={`text-lg mt-2 cursor-pointer ${
                    selectedRecipe === category.name
                      ? "text-red pb-1"
                      : ""
                  }`}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  {category.name}
                </button>
                <div
                  className={`${
                    selectedRecipe === category.name
                      ? "h-1 rounded bg-red mx-6 sm:mx-14"
                      : ""
                  }`}
                ></div>
              </div>
            ))}
        </Slider>

        <div className="flex items-center justify-center gap-4 pt-4">
          <button
            className="bg-white shadow-md p-2 rounded-full"
            onClick={handleLeftClick}
            disabled={startIndex === 0}
          >
            &larr;
          </button>

          <button
            className="bg-white shadow-md p-2 rounded-full"
            onClick={handleRightClick}
            disabled={startIndex + itemsToShow >= categories.length}
          >
            &rarr;
          </button>
        </div>
      </div>
      {selectedRecipe && (
        <>
          <ReceipeKit selectedRecipe={selectedRecipe} type={"Home"} />
        </>
      )}
    </div>
  );
};

export default DelightReceipe;