import React from 'react';
import im1 from "../../assets/images/yt.jpg";
import im2 from "../../assets/images/fm.jpeg";
import img1 from "../../assets/images/sp10.png";
import img2 from "../../assets/images/sp14.png";
import img3 from "../../assets/images/sp3.png";
import img4 from "../../assets/images/sp13.png";
import img5 from "../../assets/images/sp5.png";
import im4 from "../../assets/images/sp7.png";
import im5 from "../../assets/images/sp8.png";
import im6 from "../../assets/images/sp11.png";
import im7 from "../../assets/images/sp12.png";
import story from "../../../src/assets/images/story2.png";
import v1 from "../../assets/images/v1.png";
import v2 from "../../assets/images/v2.png";
import v3 from "../../assets/images/v3.png";
import v4 from "../../assets/images/v4.png";
import v5 from "../../assets/images/v5.png";
import bb1 from "../../assets/images/bb1.png";
import bb2 from "../../assets/images/bb2.png";
import bb3 from "../../assets/images/bb3.png";
import send from "../../assets/images/send.png";

const OurStoryTwo: React.FC = () => {

  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full aspect-w-16 aspect-h-9">
        {/* <iframe
          className="w-full object-cover"
          src="https://www.youtube.com/embed/bbNsE1N6oAE"
          title="AnyFeast meal kit #bestmealkit #cookingwithlove"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}
        <iframe width="932" height="524" src="https://www.youtube.com/embed/CBL9Qlo8RHc" title="Revolutionise Your Cooking with AnyFeast 🌟 | Explore Exciting Features Today!"   frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen></iframe>
      </div>

      {/* Welcome Section */}
      <div className="relative pb-4 lg:pb-6 w-full">
        <div className="bg-red text-white pt-2 lg:pt-20 pb-20 lg:pb-32 text-center px-6 sm:px-10 md:px-20 lg:px-32 xl:px-40 w-full">
          <p className=" font-sans text-justify lg:text-xl text-[14px] leading-relaxed mx-auto mt-4 lg:mt-6">
            <b>Hi, I’m Pankaj Kale</b>—a foodie, traveller, and advocate for sustainability and innovation. AnyFeast was inspired by my journey of overcoming unhealthy eating habits and rediscovering the joy of cooking. 
            <p>With a passion for fresh, authentic flavours, I set out to make healthy, sustainable cooking accessible to everyone.</p>
            <p>  At AnyFeast, we believe small actions create big change.By choosing local ingredients and reducing waste, we can support communities and build a healthier, more sustainable future.</p><p> Join us—let’s cook sustainably, shop local, and stay healthier, one meal at a time!</p>

          </p>
        </div>

        {/* Family Image */}
        <div className="flex justify-center lg:-mt-28 md:px-4 px-8 -mt-20 lg:px-none">
          <img
            src={story} // Replace with your image path
            alt="Family"
            className="rounded-[28px] shadow-lg"
          />
        </div>
      </div>

      {/* Sponsor Section */}
      <div className="lg:mt-12 mt-6 text-center w-full pb-10 lg:pb-10">
        <h3 className="lg:text-2xl text-xl font-bold mb-10 lg:mb-16 font-sans text-black">Proudly Supported By</h3>
        <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-6 lg:px-10 px-4">
          <div className="flex justify-center items-center">
            <img src={img1} alt="Microsoft" className="lg:h-20 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img2} alt="Founder Institute" className="lg:h-24 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img3} alt="Virgin Start" className="lg:h-20 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img4} alt="NatWest" className="lg:h-16 md:h-8 h-4" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img5} alt="Wadhwani Foundation" className="lg:h-24 h-8" />
          </div>
        </div>
      </div>


      <div className="lg:max-w-7xl md:max-w-7xl lg:mx-auto md:mx-auto space-y-8 lg:pb-6 pb-6 lg:mx-none md:mx-6 mx-6">
        {/* Vision Section */}
        <div className="bg-white rounded-lg border shadow-xl overflow-hidden flex flex-row p-3 lg:p-6 lg:p-10 lg:gap-6 md:p-6 md:p-10 md:gap-6 space-x-0 mb-4 lg:mb-16 md:mb-16">
          {/* Text Section */}
          <div className="flex-1 text-left">
            <h1 className=" lg:text-4xl md:2xl font-bold text-black pb-2 lg:py-6 lg:pb-4 lg:border-b-4 md:py-6 md:pb-4 md:border-b-4 border-b-2 border-gray-500 font-sans">
              Our <span className="text-red">Vision</span>
            </h1>

            <p className="mt-2 lg:mt-8 md:mt-8 font-sans text-[#313131] leading-4 md:leading-relaxed lg:leading-relaxed text-[12px] lg:text-xl md:text-[15px]">
            To create a world where cooking inspires healthier lives, stronger communities, and a sustainable planet—one meal at a time. By harnessing the power of AI and cutting-edge technology, we empower individuals with personalised, eco-friendly meal solutions that nourish both people and the planet.
 
            </p>
          </div>
          {/* Image Section */}
          <div className="flex-1 justify-end justify-items-end md:justify-items-center md:pt-8">
            <img
              src={im4}
              alt="Vision"
              className="hidden lg:block md:inline-block rounded-lg w-3/4 object-cover"
            />
            {/* Responsive Image */}
            <img
              src={im6}
              alt="Vision"
              className="lg:hidden md:hidden rounded-lg object-cover justify-items-center pt-6"
            />
          </div>
        </div>

        {/* Mission Section */}
        <div className="bg-white rounded-lg border shadow-xl overflow-hidden flex flex-row p-3 lg:p-6 lg:p-10 lg:gap-6 md:p-6 md:p-10 md:gap-6 space-x-0 mb-4 lg:mb-16 md:mb-16">
          {/* Image Section */}
          <div className="flex-1 justify-items-start md:justify-items-center md:pt-8">
            <img
              src={im5}
              alt="Vision"
              className="hidden lg:block md:inline-block rounded-lg w-3/4 object-cover"
            />
            {/* Responsive Image */}
            <img
              src={im7}
              alt="Vision"
              className="lg:hidden md:hidden rounded-lg object-cover justify-items-center pt-6"
            />
          </div>
          {/* Text Section */}
          <div className="flex-1 text-left">
            <h1 className=" lg:text-4xl md:2xl  font-bold text-[#313131] pb-2 lg:py-6 lg:pb-4 lg:border-b-4 md:py-6 md:pb-4 md:border-b-4 border-b-2 border-gray-500 font-sans">
              Our <span className="text-red">Mission</span>
            </h1>
            <p className="mt-2 lg:mt-8 md:mt-8 font-sans text-[#313131] leading-4 md:leading-relaxed lg:leading-relaxed text-[12px] lg:text-xl md:text-[15px]">
            At AnyFeast, we empower busy professionals to embrace healthier, more sustainable eating through responsibly sourced ingredients, simple culinary education, and innovative technology. By leveraging AI and the latest advancements, we personalise meal solutions, reduce food waste, and support local economies. Our mission is to transform the way people cook, eat, and connect with food while fostering a healthier planet and thriving communities.
            </p>
          </div>
        </div>
      </div>

      <div className="relative pb-4 lg:pb-6 w-full">
        <div className="mb-4 lg:mb-none md:mb-none bg-[#FFE1DF] lg:h-auto h-auto text-[#1F1F1F] font-sans lg:pt-20 lg:pb-32 md:pt-16 md:pb-20 text-center py-3 lg:py-none md:py-none px-6 md:px-20 lg:px-32 xl:px-40 w-full">
          <h1 className='lg:text-4xl md:2xl font-bold'>Our Company Values</h1>
          <p className="font-normal lg:text-xl md:text-lg text-sm leading-relaxed mx-auto font-sans mt-2 mb-2 lg:mx-24 lg:mb-none lg:mt-6">
            Just like a delicious dish, any company worth its salt is made of a variety of different people, teams and
            departments. No matter how many people help us grow AnyFeast, we all share the same values, which are
          </p>
        </div>


        <div className="lg:grid md:grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 lg:gap-8 md:gap-6 px-2 lg:-mt-20 lg:!pl-20 md:-mt-20 md:!pl-4 lg:mb-8 md:mb-6 mb-2 justify-items-center">
          <div className="max-w-sm p-3">
            <img
              src={v1}
              alt="Passion"
              className="rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Passion</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              Cooking is an art, a skill, and a way to change your perspective. We’re passionate about cooking and sharing new ways to make great food with people all over the world.
            </p>
          </div>
          <div className="max-w-sm p-3">
            <img
              src={v2}
              alt="Innovation"
              className=" rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Innovation</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              While dozens of authentic and traditional recipes are the product of centuries of practice, we’re not scared to switch things up when it comes to finding better, faster, and more convenient ways to try new cuisines at home. That’s why we use revolutionary technology, platforms, and metrics to create one simple-to-use brand.
            </p>
          </div>
          <div className="max-w-sm  p-3">
            <img
              src={v3}
              alt="Integrity"
              className="rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Integrity</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              We take pride in being able to give back to our community in an honest and transparent way. Not only do we donate a percentage of our profits to people who need the support, but we also package our ingredients in eco-friendly materials to take better care of the environment.
            </p>
          </div>

          <div className="lg:col-span-3 md:grid lg:flex md:flex justify-center gap-8">
            <div className="max-w-sm p-3">
              <img
                src={v4}
                alt="Diversity"
                className="rounded-lg mb-4"
              />
              <h1 className="lg:text-lg text-xl font-bold mb-2">Diversity</h1>
              <p className="font-normal md:text-sm leading-relaxed text-gray-600">
                Cooking international recipes means respecting the people and communities who created them in the first place. We value diversity above all else!
              </p>
            </div>
            <div className="max-w-sm p-3">
              <img
                src={v5}
                alt="Customer-Focus"
                className="rounded-lg mb-4"
              />
              <h1 className="lg:text-lg text-xl font-bold mb-2">Customer-Focus</h1>
              <p className="font-normal md:text-sm leading-relaxed text-gray-600">
                We care about what our valued customers think. Your feedback helps us continuously create incredible offers, better services, and delicious international recipes. So, tell us what you think and how you think we can improve, change, and grow.
              </p>
            </div>
          </div>
        </div>

        <div className="lg:py-8 py-2 px-6 md:px-8 lg:px-32 bg-white">
          {/* Heading Section */}
          <div className="mb-8">
            <h2 className="lg:text-3xl text-2xl font-bold mb-2 font-sans">
              From our <span className="text-red-500">Blog</span>
            </h2>
            <p className="text-[#000] lg:text-xl text-[13px] font-sans">
              Dive into our blog to discover what’s cooking at AnyFeast and the passion that drives us
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
            <div className="overflow-hidden">
              <img
                src={bb1}
                alt="Blog 1"
              //className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-2">
                  Published on June 26, 2024 | 2 min read
                </p>
                <h3 className="text-lg font-bold mb-2 line-clamp-1">From Management Consultant to AI Food Tech Innovator: My Journey to Launch AnyFeast for Health-Conscious Professionals</h3>
                <p className="text-gray-600 mb-4 flex items-center justify-between line-clamp-2">
                  Today, I want to share my personal journey from being a management consultant to founding...
                  <img
                    src={send}
                    alt="Blog 2"
                    className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                  />
                </p>
              </div>
            </div>

            <div className="overflow-hidden">
              <img
                src={bb2}
                alt="Blog 2"
              // className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-2">
                  Published on July 14, 2024 | 2 min read
                </p>
                <h3 className="text-lg font-bold mb-2 line-clamp-1">Celebrating a Milestone: Anyfeast's Demo Day</h3>
                <p className="text-gray-600 mb-4 flex items-center justify-between line-clamp-2">
                  "If you don't dream, you won't achieve anything."- Richard Branson. For me, these...

                  <img
                    src={send}
                    alt="Blog 2"
                    className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                  />
                </p>
              </div>
            </div>

            <div className="overflow-hidden">
              <img
                src={bb3}
                alt="Blog 3"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-2">
                  Published on August 30, 2024 | 2 min read
                </p>
                <h3 className="text-lg font-bold mb-2 line-clamp-1">From Vision to Victory: Celebrating Anyfeast's</h3>
                <p className="text-gray-600 mb-4 flex items-center justify-between line-clamp-2">
                  "Create a clear vision for your business, and then stick to it." – Adeo Ressi
                  <img
                    src={send}
                    alt="Blog 2"
                    className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default OurStoryTwo;
